
export const AccessLevel = {
  OWNER: 50,
  MAINTAINER: 40,
  EMPLOYEE: 30,
  OBSERVER: 20,
  GUEST: 10,
}

export class SiteMember {

accessLevels() {
  return [
    {value: AccessLevel.OWNER ,text: this.accessLevelName(AccessLevel.OWNER)},
    {value: AccessLevel.MAINTAINER ,text: this.accessLevelName(AccessLevel.MAINTAINER)},
    {value: AccessLevel.EMPLOYEE ,text: this.accessLevelName(AccessLevel.EMPLOYEE)},
    {value: AccessLevel.GUEST ,text: this.accessLevelName(AccessLevel.GUEST)}
  ]
}

accessLevelName(value) {
  switch(value) {
    case AccessLevel.OWNER: return "Właściciel"
    case AccessLevel.MAINTAINER: return "Opiekun"
    case AccessLevel.EMPLOYEE: return "Pracownik"
    // case AccessLevel.OBSERVER: return "Obserwator"
    case AccessLevel.GUEST: return "Gość"
  }
}

}

export default new SiteMember()